import React, { Component } from "react";

class ContactOne extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 offset-lg-3 order-2 order-lg-1">
                            <div className="section-title text-center mb--50">
                                <h2 className="title">Contact me</h2>
                                  <p className="description">I am available for freelance work. </p>
                                  <p>Connect with me via phone: <a href="tel:+48 733 019 288 ">+48 733 019 288 </a> <br />or email:
                                    <a href="mailto:irream.language.school@gmail.com"> irream.language.school@gmail.com</a>
                                  </p>

                                <div className="slide-btn">
                                  <a className="rn-button-style--2 btn-solid" href="https://www.instagram.com/irenessa777/">Instagram</a>
                                  <a className="rn-button-style--2 btn-solid" href="https://www.facebook.com/profile.php?id=100005576742970">Facebook</a>
                                </div>
                            </div>
                            {/*<div className="form-wrapper">*/}

                                {/*<form>*/}
                                    {/*<label htmlFor="item01">*/}
                                        {/*<input*/}
                                            {/*type="text"*/}
                                            {/*name="name"*/}
                                            {/*id="item01"*/}
                                            {/*value={this.state.rnName}*/}
                                            {/*onChange={(e)=>{this.setState({rnName: e.target.value});}}*/}
                                            {/*placeholder="Your Name *"*/}
                                        {/*/>*/}
                                    {/*</label>*/}

                                    {/*<label htmlFor="item02">*/}
                                        {/*<input*/}
                                            {/*type="text"*/}
                                            {/*name="email"*/}
                                            {/*id="item02"*/}
                                            {/*value={this.state.rnEmail}*/}
                                            {/*onChange={(e)=>{this.setState({rnEmail: e.target.value});}}*/}
                                            {/*placeholder="Your email *"*/}
                                        {/*/>*/}
                                    {/*</label>*/}

                                    {/*<label htmlFor="item03">*/}
                                        {/*<input*/}
                                            {/*type="text"*/}
                                            {/*name="subject"*/}
                                            {/*id="item03"*/}
                                            {/*value={this.state.rnSubject}*/}
                                            {/*onChange={(e)=>{this.setState({rnSubject: e.target.value});}}*/}
                                            {/*placeholder="Write a Subject"*/}
                                        {/*/>*/}
                                    {/*</label>*/}
                                    {/*<label htmlFor="item04">*/}
                                        {/*<textarea*/}
                                            {/*type="text"*/}
                                            {/*id="item04"*/}
                                            {/*name="message"*/}
                                            {/*value={this.state.rnMessage}*/}
                                            {/*onChange={(e)=>{this.setState({rnMessage: e.target.value});}}*/}
                                            {/*placeholder="Your Message"*/}
                                        {/*/>*/}
                                    {/*</label>*/}
                                    {/*<button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>*/}
                                {/*</form>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className="col-lg-6 order-1 order-lg-2">*/}
                            {/*<div className="thumbnail mb_md--30 mb_sm--30">*/}
                                {/*<img src="/assets/images/about/hire-me.jpg" alt="trydo"/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;