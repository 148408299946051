import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import ServiceList from "../elements/service/ServiceOne";
import Helmet from "../component/common/Helmet";
import image1 from "../../public/assets/images/bg/reviews.png";




const SlideList = [
    {
        textPosition: 'text-left',
        category: 'WELCOME',
        title: '<span>BOOST</span> YOUR <br>COMMUNICATION SKILLS<br>',
        description: '',
        buttonText: 'Instagram',
        buttonLink: 'https://www.instagram.com/irenessa777/',
        buttonFacebook: 'Facebook',
        buttonFacebookLink: 'https://www.facebook.com/profile.php?id=100005576742970'
    }
]
const PortfolioLanding = () => {
    let title = 'About Me',
        description = 'IRREAM language school is a place where you get confidence in speaking\n' +
          'a foreign language. Communication skills are essential in collaboration with\n' +
          'foreigners, career development and private life. If you would like to improve\n' +
          'these skills, we’ll help you fulfil your potential.'
    return (
        <div>
            <Helmet pageTitle="English Courses" />
            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image background-image-iryna" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn">
                                              <a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a>
                                              <a className="rn-button-style--2 btn-solid" href={`${value.buttonFacebookLink}`}>{value.buttonFacebook}</a>
                                            </div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start About Area */}
            <div id="about">
                <div className="about-area about-position-top pb--20  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/main-photo.jpeg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-8">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            <div id="learning-steps" className="fix">
              <div className="portfolio-area ptb--20 bg_color--1">
                <div className="portfolio-sacousel-inner">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                          <h2 className="title">Learning Steps</h2>
                          <p>- Placement test (define your level) <br/>
                            - set a plan for your classes, you can choose a number of classes (12/24/36/48) <br/>
                            - while learning you will have classes with different teachers, native
                            speakers, so you won’t be used to one accent; you can participate in
                            speaking clubs <br/>
                            - get a certificate</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper ptb--20 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">What we offer</h2>
                                    <p>We prepare individual learning programmes for students of different age groups and backgrounds
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            {/* End Service Area  */}

          {/* Start CounterUp Area */}
          {/*<div className="rn-counterup-area pt--80 pt_sm--50 pt_md--50 pb--120 bg_color--1">*/}
            {/*<div className="container">*/}
              {/*<CounterOne />*/}
            {/*</div>*/}
          {/*</div>*/}
          {/* End CounterUp Area */}


          {/* Start Testimonial Area */}
          <div className="rn-testimonial-area bg_color--5" id="testimonial">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Check my testimonials</h2>
                    <p>"Learning a language is not a destination, but a journey" - Jean Brunet <br/>I'll help you to make it astonishing </p>
                  </div>
                </div>
              </div>
              <div className="photo-wrapper">
                <img src={image1} alt="Testimonial Images"/>
                {/*<img src={image2} alt="Testimonial Images"/>*/}
                {/*<img src={image3} alt="Testimonial Images"/>*/}
                {/*<img src={image4} alt="Testimonial Images"/>*/}
                {/*<img src={image5} alt="Testimonial Images"/>*/}
                {/*<img src={image6} alt="Testimonial Images"/>*/}
                {/*<img src="../../assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>*/}
              </div>
              {/*<Testimonial />*/}
            </div>
          </div>
          {/* End Testimonial Area */}

            {/* Start Portfolio Area */}
          {/*<div id="pricing" className="fix">*/}
              {/*<div className="portfolio-area ptb--120 bg_color--1">*/}
                  {/*<div className="portfolio-sacousel-inner">*/}
                      {/*<div className="container">*/}
                          {/*<div className="row">*/}
                              {/*<div className="col-lg-12">*/}
                                  {/*<div className="section-title text-center service-style--3 mb--30 mb_sm--0">*/}
                                      {/*<h2 className="title">My Latest Project</h2>*/}
                                      {/*<p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>*/}
                                  {/*</div>*/}
                              {/*</div>*/}
                          {/*</div>*/}

                          {/*<div className="row">*/}
                              {/*<div className="col-lg-12">*/}
                                  {/*<div className="view-more-btn mt--60 mt_sm--30 text-center">*/}
                                      {/*<a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>*/}
                                  {/*</div>*/}
                              {/*</div>*/}
                          {/*</div>*/}
                      {/*</div>*/}
                  {/*</div>*/}
              {/*</div>*/}
          {/*</div>*/}
            {/* End Portfolio Area */}

            {/* Start Blog Area */}
            {/*<div id="blog" className="fix">*/}
                {/*<div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row align-items-end">*/}
                            {/*<div className="col-lg-12 col-md-12 col-sm-12 col-12">*/}
                                {/*<div className="section-title text-center">*/}
                                    {/*<h2>Latest News</h2>*/}
                                    {/*<p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="row mt--60 mt_sm--40">*/}
                            {/*{PostList.map((value , i ) => (*/}
                                {/*<div className="col-lg-4 col-md-6 col-12" key={i}>*/}
                                    {/*<div className="blog blog-style--1">*/}
                                        {/*<div className="thumbnail">*/}
                                            {/*<a href="/blog-details">*/}
                                                {/*<img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>*/}
                                            {/*</a>*/}
                                        {/*</div>*/}
                                        {/*<div className="content">*/}
                                            {/*<p className="blogtype">{value.category}</p>*/}
                                            {/*<h4 className="title"><a href="/blog-details">{value.title}</a></h4>*/}
                                            {/*<div className="blog-btn">*/}
                                                {/*<a className="rn-btn text-white" href="/blog-details">Read More</a>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*))}*/}
                        {/*</div>    */}
                    {/*</div>    */}
                {/*</div>*/}
            {/*</div>*/}
            {/* End Blog Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--60 bg_color--5">
                    <ContactOne />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default PortfolioLanding;
