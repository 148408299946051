import React, { Component } from "react";
import { FaCircle } from "react-icons/fa";
import { FiLayers , FiTrendingUp , FiUsers , FiMic, FiEdit3, FiInbox, FiCornerDownRight, FiMinus, FiChevronRight, FiCheckCircle } from "react-icons/fi";
import one from "../../../public/assets/images/service/one.jpg"
import two from "../../../public/assets/images/service/two.jpg"
import three from "../../../public/assets/images/service/three.jpg"
import four from "../../../public/assets/images/service/four.jpg"
import five from "../../../public/assets/images/service/five.jpg"
import six from "../../../public/assets/images/service/six.jpg"

const ServiceList = [
    {
        icon: <img src={one} />,
        title: 'Individual lessons',
        first: 'tailored teaching approach',
        second: 'analysis of weak points and focus on improving them',
        third: 'you choose the topics you are interested in',
        four: 'verbal practice of vocabulary and grammar (written practice – homework)',
    },
    {
        icon: <img src={two} />,
        title: 'Pronunciation course',
        first: 'focus on improving pronunciation',
        second: 'how to articulate your mouth to correctly produce sounds',
        third: 'prepared materials (recordings)',
        four: 'improvement of fluency',
        five: 'online course 12 lessons + feedback'
    },
    {
        icon: <img src={three} />,
        title: 'Business English',
        first: '13 topics B1+ level related to business:(Finance Leadership Negotiations Management Real estate, written + verbal exercises)',
        second: 'interviews with people who are experts in a particular subject',
        third: 'verbal practice of vocabulary and grammar (written practice – homework)',
        four: 'face to face lesson with a teacher'
    },
    {
        icon: <img src={four} />,
        title: 'Group classes',
        first: 'chosen topics according to your interests',
        second: 'conversations with a native speaker',
        third: 'verbal practice of vocabulary and grammar (written practice – homework)',
        four: 'regular revision of materials',
    },
    {
        icon: <img src={five} />,
        title: 'Conversations',
        first: 'focus on communication skills',
        second: 'body language',
        third: 'tips on how to gain confidence',
        four: 'verbal practice of vocabulary and grammar (written practice – homework)',
        five: 'are organised once a month'
    },
    {
        icon: <img src={six} />,
        title: 'Intensive grammar',
        first: 'focus on practising grammar ',
        second: 'all aspects of grammar',
        third: 'simplified theory + verbal practice during the lesson',
        four: 'prepared written materials for practice (homework)',
        five: 'online course 12 lessons + feedback'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                  {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>

                                  {val.first ? <div className="circle-wrapper"><div><FaCircle /></div> <div><span>{val.first}</span></div></div>  : ''}
                                  {val.second ? <div className="circle-wrapper"><div><FaCircle /></div> <div><span>{val.second}</span></div></div>  : ''}
                                  {val.third ? <div className="circle-wrapper"><div><FaCircle /></div> <div><span>{val.third}</span></div></div>  : ''}
                                    {val.four ? <div className="circle-wrapper"><div><FaCircle /></div> <div><span>{val.four}</span></div></div>  : ''}
                                  {val.five ? <div className="circle-wrapper"><div><FaCircle /></div> <div><span>{val.five}</span></div></div>  : ''}

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;